import * as React from "react"
import { useContext, useEffect, useState } from "react"
import Layout from "../components/Layout"
import { Icon } from "semantic-ui-react"
import { BasketContext } from "../social-supermarket/context/BasketProvider"
import SEO from "../components/Seo"
import styled from "styled-components"
import { BasketContextType } from "../social-supermarket/model/BasketContextType"
import { borderGrey, lightGray } from "../social-supermarket/constants/colors"
import AddressType from "../social-supermarket/model/AddressType"
import BasketItems from "../social-supermarket/pages/basket/BasketItems"
import { formatPrice } from "../social-supermarket/util/productUtil"
import * as tracker from "../social-supermarket/tracking/tracker"
import { useLocation } from "@reach/router"
import queryString from "query-string"

const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding: 7%;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`
const Header = styled.div`
  text-align: center;
  font-size: 1.2em;
  margin: 20px 0 10px 0;
`
const SubHeader = styled.div`
  text-align: center;
`
const Description = styled.div``
const Addresses = styled.div`
  display: flex;
  margin-top: 20px;
`
const Address = styled.div`
  flex: 1;
  background-color: ${lightGray};

  margin: 5px;
  padding: 20px;
`
const Items = styled.div`
  padding: 20px 0;
`
const Meta = styled.div`
  padding: 10px 10px 5px 10px;
  font-size: 0.9em;
  border-top: 1px solid ${borderGrey};
`
const MetaItem = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`

const MetaTitle = styled.div`
  width: 150px;
  text-align: right;
`
const MetaContent = styled.div`
  width: 70px;
  text-align: right;
`
const Summary = styled.div``

const OrderSuccess = () => {
  const { search } = useLocation()
  const { orderId } = queryString.parse(search)

  const basketContext = useContext(BasketContext)
  const [savedContext, setSavedContext] = useState<BasketContextType>(null)
  const theBasket: BasketContextType = savedContext ? savedContext : basketContext

  useEffect(() => {
    setSavedContext({ ...basketContext })
    basketContext.clearBasket()

    tracker.orderSuccessPage()
  }, [])

  const renderAddress = (address: AddressType) => {
    return address ? (
      <div>
        <div>
          {address.firstName} {address.lastName}
        </div>
        {address.company && <div>{address.address2}</div>}
        <div>{address.address1}</div>
        {address.address2 && <div>{address.address2}</div>}
        <div>{address.city}</div>
        <div>{address.country}</div>
        <div>{address.postCode}</div>
      </div>
    ) : null
  }

  const render = () => {
    const voucher =
      theBasket.basket?.coupons && theBasket.basket?.coupons.length > 0
        ? theBasket.basket?.coupons[0].code
        : ""
    return (
      <Container>
        <IconContainer>
          <Icon name="check circle outline" size="huge" style={{ color: "green" }} />
        </IconContainer>
        <Header>
          Thank You! Your order <b>#{orderId}</b> has been placed.
        </Header>
        <SubHeader>
          We have sent an email to {theBasket.checkoutFormState?.contactInfo?.email} with your order
          confirmation.
        </SubHeader>
        <Addresses>
          <Address>
            <b>Billing Address</b>
            {renderAddress(
              theBasket.checkoutFormState?.billingSame
                ? theBasket.checkoutFormState?.shippingAddress
                : theBasket.checkoutFormState?.billingAddress
            )}
          </Address>
          <Address>
            <b>Shipping Address</b>
            {renderAddress(theBasket.checkoutFormState?.shippingAddress)}
          </Address>
        </Addresses>
        <Items>
          <BasketItems items={theBasket.basket.items} />
        </Items>
        <Summary>
          <Meta>
            <MetaItem>
              <MetaTitle>Subtotal:</MetaTitle>
              <MetaContent>{formatPrice(theBasket.basket.subTotal)}</MetaContent>
            </MetaItem>
            <MetaItem>
              <MetaTitle>Discount:</MetaTitle>
              <MetaContent>({formatPrice(theBasket.basket.discountTotal)})</MetaContent>
            </MetaItem>
            <MetaItem>
              <MetaTitle>Shipping:</MetaTitle>
              <MetaContent>{formatPrice(theBasket.basket.shippingMethod.total)}</MetaContent>
            </MetaItem>
            <MetaItem>
              <MetaTitle>
                <b>Total:</b>
              </MetaTitle>
              <MetaContent>
                <b>{formatPrice(theBasket.basket.total)}</b>
              </MetaContent>
            </MetaItem>
            <MetaItem>(Includes {formatPrice(theBasket.basket.totalTax)} VAT)</MetaItem>
          </Meta>
        </Summary>
        <img
          src={`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=28951&amount=${theBasket.basket?.total}&ch=aw&cr=GBP&parts=DEFAULT:${theBasket.basket?.total}&ref=${orderId}&testmode=0&vc=${voucher}`}
          style={{ display: "none" }}
          width="0"
        />
      </Container>
    )
  }
  return (
    <Layout>
      <SEO title="Order Success" description={"Order success"} />
      {theBasket && render()}
    </Layout>
  )
}

export default OrderSuccess
